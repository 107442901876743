import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AnswerDto {
  answerId?: Maybe<Scalars['String']>;
  answerOrder?: Maybe<Scalars['Float']>;
  answerText?: Maybe<Scalars['String']>;
};

export class AnsweredQuestionDto {
  answers: Array<AnswerDto>;
  questionId: Scalars['String'];
  questionOrder: Scalars['Float'];
  questionText: Scalars['String'];
};

export class AuthInput {
  email: Scalars['String'];
  origin?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class ChangePasswordInput {
  password: Scalars['String'];
  passwordOld: Scalars['String'];
};

export class CreateAdminInput {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export class CreateToolInput {
  toolId: Scalars['String'];
};

export class CreateUserInput {
  birthday?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  profile: Scalars['String'];
};

export class CreateUserTermInput {
  isAwareDataSharedWithDoctor: Scalars['Boolean'];
  isAwareMedicalGuidance: Scalars['Boolean'];
  isAwareTermsOfUse: Scalars['Boolean'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class DirectionInput {
  correctDirection: Scalars['String'];
  direction: Scalars['String'];
};

export class EnumType {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export class FindStripes {
  isBlocked: Scalars['Boolean'];
  stripe: Stripe;
};

export class FindStripesDto {
  result: Array<FindStripes>;
};

export class FindTools {
  isBlocked: Scalars['Boolean'];
  tool: Tool;
};

export class FindToolsDto {
  result: Array<FindTools>;
};

export class Mutation {
  changePassword: DefaultMessage;
  /** Create new Admin */
  createAdmin: User;
  /** Create new User */
  createUser: User;
  /** Remove Admin By Id */
  deleteAdmin: DefaultMessage;
  forgotPassword: DefaultMessage;
  login: AuthType;
  resetPassword: DefaultMessage;
  /** respond stripe 8 */
  respondStripeEight: DefaultMessage;
  /** respond stripe 5 */
  respondStripeFive: DefaultMessage;
  /** respond stripe 4 */
  respondStripeFour: DefaultMessage;
  /** respond stripe 1 */
  respondStripeOne: DefaultMessage;
  /** respond stripe 7 */
  respondStripeSeven: DefaultMessage;
  /** respond stripe 6 */
  respondStripeSix: DefaultMessage;
  /** respond stripe 3 */
  respondStripeThree: DefaultMessage;
  /** respond stripe 2 */
  respondStripeTwo: DefaultMessage;
  /** Save Search Form */
  saveSearchFormAnswer: UserAnswersDto;
  /** Unlock Tool by toolId */
  unlockTool: DefaultMessage;
  /** Update admin */
  updateAdmin: User;
};


export class MutationChangePasswordArgs {
  data: ChangePasswordInput;
};


export class MutationCreateAdminArgs {
  data: CreateAdminInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationDeleteAdminArgs {
  id: Scalars['String'];
};


export class MutationForgotPasswordArgs {
  email: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationResetPasswordArgs {
  data: ResetPasswordInput;
};


export class MutationRespondStripeEightArgs {
  data: RespondStripeInput;
};


export class MutationRespondStripeFiveArgs {
  data: RespondQuestionTextInput;
};


export class MutationRespondStripeFourArgs {
  data: RespondQuestionAnswerInput;
};


export class MutationRespondStripeOneArgs {
  data: RespondQuestionWithTerm;
};


export class MutationRespondStripeSevenArgs {
  data: RespondStripeInput;
};


export class MutationRespondStripeSixArgs {
  data: RespondQuestionDirectionInput;
};


export class MutationRespondStripeThreeArgs {
  data: RespondQuestionTextInput;
};


export class MutationRespondStripeTwoArgs {
  data: RespondQuestionAnswerInput;
};


export class MutationSaveSearchFormAnswerArgs {
  data: SaveQuestionAnswerInput;
};


export class MutationUnlockToolArgs {
  data: CreateToolInput;
};


export class MutationUpdateAdminArgs {
  data: UpdateAdminInput;
};

export class Query {
  /** Return Admin By Id */
  adminById: User;
  /** Return user answer saved */
  answerSaveByUserId?: Maybe<UserAnswersDto>;
  /** Return User Logged */
  currentUser: User;
  /** Return All By Type */
  enumByType: Array<EnumType>;
  /** Return Enum Type by Id */
  enumTypeById: EnumType;
  /** Return All Enum Types */
  enumTypes: Array<EnumType>;
  /** Return User Intern By id */
  findUserInternById: User;
  /** Return Search Form Question with answers */
  getSearchForm: Array<SearchFormQuestion>;
  stripe: Stripe;
  /** Return User Stripes */
  stripes: FindStripesDto;
  /** Return User Tools */
  tools: FindToolsDto;
  userByGenderDashboard: UserGenderDashboard;
  /** Return User By Id */
  userById: User;
  userPerDayDashboard: Array<UserPerDayDashboard>;
  userSearchFormPerDayDashboard: Array<UserSearchFormDashboard>;
  userStripeCompletedDashboard: Array<UserStripeCompletedDashboard>;
  /** Return All users paged */
  users: UserPage;
  /** Return All users by profile */
  usersByProfile: Array<User>;
};


export class QueryAdminByIdArgs {
  id: Scalars['String'];
};


export class QueryAnswerSaveByUserIdArgs {
  userId: Scalars['String'];
};


export class QueryEnumByTypeArgs {
  type: Scalars['String'];
};


export class QueryEnumTypeByIdArgs {
  id: Scalars['String'];
};


export class QueryFindUserInternByIdArgs {
  id: Scalars['String'];
};


export class QueryStripeArgs {
  id: Scalars['String'];
};


export class QueryUserByGenderDashboardArgs {
  data: SearchDashboardInput;
};


export class QueryUserByIdArgs {
  id: Scalars['String'];
};


export class QueryUserPerDayDashboardArgs {
  data: SearchDashboardInput;
};


export class QueryUserSearchFormPerDayDashboardArgs {
  data: SearchDashboardInput;
};


export class QueryUserStripeCompletedDashboardArgs {
  data: SearchDashboardInput;
};


export class QueryUsersArgs {
  data: SearchUserInput;
};


export class QueryUsersByProfileArgs {
  profile: Scalars['String'];
};

export class ResetPasswordInput {
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export class RespondAnswerInput {
  answerIds: Array<Scalars['String']>;
  questionId: Scalars['String'];
};

export class RespondDirectionInput {
  directions: Array<DirectionInput>;
  order: Scalars['Float'];
};

export class RespondQuestionAnswerInput {
  answers: Array<RespondAnswerInput>;
  stripeId: Scalars['String'];
};

export class RespondQuestionDirectionInput {
  answers: Array<RespondAnswerInput>;
  directions: Array<RespondDirectionInput>;
  stripeId: Scalars['String'];
};

export class RespondQuestionTextInput {
  answers: Array<RespondAnswerInput>;
  stripeId: Scalars['String'];
  texts: Array<RespondTextInput>;
};

export class RespondQuestionWithTerm {
  answers: Array<RespondAnswerInput>;
  stripeId: Scalars['String'];
  terms: CreateUserTermInput;
};

export class RespondStripeInput {
  stripeId: Scalars['String'];
};

export class RespondTextInput {
  questionId: Scalars['String'];
  text: Scalars['String'];
};

export class SaveQuestionAnswerInput {
  answers: Array<SearchFormAnswerInput>;
};

export class SearchDashboardInput {
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};

export class SearchFormAnswerInput {
  answerText?: InputMaybe<Scalars['String']>;
  searchFormQuestionAnswerId?: InputMaybe<Array<Scalars['String']>>;
  searchFormQuestionId: Scalars['String'];
};

export class SearchFormQuestion {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  questionOrder: Scalars['Float'];
  searchFormQuestionAnswer?: Maybe<Array<SearchFormQuestionAnswer>>;
  searchFormUserAnswer?: Maybe<Array<SearchFormUserAnswer>>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class SearchFormQuestionAnswer {
  answerOrder: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  searchFormQuestion: SearchFormQuestion;
  searchFormUserAnswer?: Maybe<Array<SearchFormUserAnswer>>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class SearchFormUserAnswer {
  answerText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  searchFormQuestion: SearchFormQuestion;
  searchFormQuestionAnswer?: Maybe<SearchFormQuestionAnswer>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class SearchUserInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class Stripe {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  order: Scalars['Float'];
  stripeQuestion?: Maybe<Array<StripeQuestion>>;
  stripeUser?: Maybe<Array<StripeUser>>;
  updatedAt: Scalars['DateTime'];
};

export class StripeDirection {
  correctDirection: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  direction: Scalars['String'];
  id: Scalars['ID'];
  order: Scalars['Float'];
  stripeUser: StripeUser;
  updatedAt: Scalars['DateTime'];
};

export class StripeQuestion {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  order: Scalars['Float'];
  questionDescription?: Maybe<Scalars['String']>;
  questionText: Scalars['String'];
  stripe: Stripe;
  stripeQuestionAnswer?: Maybe<Array<StripeQuestionAnswer>>;
  stripeUserAnswer?: Maybe<Array<StripeUserAnswer>>;
  updatedAt: Scalars['DateTime'];
};

export class StripeQuestionAnswer {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order: Scalars['Float'];
  stripeQuestion: StripeQuestion;
  stripeUserAnswer?: Maybe<Array<StripeUserAnswer>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class StripeUser {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  stripe: Stripe;
  stripeDirection?: Maybe<Array<StripeDirection>>;
  stripeUserAnswer?: Maybe<Array<StripeUserAnswer>>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class StripeUserAnswer {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  stripeQuestion: StripeQuestion;
  stripeQuestionAnswer?: Maybe<StripeQuestionAnswer>;
  stripeUser: StripeUser;
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export class Tool {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  toolUser?: Maybe<Array<ToolUser>>;
  updatedAt: Scalars['DateTime'];
};

export class ToolUser {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  tool: Tool;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class UpdateAdminInput {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export class User {
  access?: Maybe<Array<UserAccess>>;
  answerSearchFormAt?: Maybe<Scalars['DateTime']>;
  birthday?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAnswerSearchForm: Scalars['Boolean'];
  name: Scalars['String'];
  origin?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  profile: Scalars['String'];
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  searchFormUserAnswer?: Maybe<Array<SearchFormUserAnswer>>;
  stripeUser?: Maybe<Array<StripeUser>>;
  toolUser?: Maybe<Array<ToolUser>>;
  updatedAt: Scalars['DateTime'];
  userTerm?: Maybe<Array<UserTerm>>;
};

export class UserAccess {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ip: Scalars['String'];
  user: User;
};

export class UserAnswersDto {
  questions: Array<AnsweredQuestionDto>;
  user: User;
};

export class UserGenderDashboard {
  qntOther: Scalars['Int'];
  qtdFemale: Scalars['Int'];
  qtdMale: Scalars['Int'];
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<User>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserPerDayDashboard {
  createdDate: Scalars['DateTime'];
  qtdUser: Scalars['Int'];
};

export class UserSearchFormDashboard {
  createdDate: Scalars['DateTime'];
  qtdUser: Scalars['Int'];
};

export class UserStripeCompletedDashboard {
  qntCompleted?: Maybe<Scalars['Int']>;
  stripe?: Maybe<Scalars['String']>;
};

export class UserTerm {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isAwareDataSharedWithDoctor: Scalars['Boolean'];
  isAwareMedicalGuidance: Scalars['Boolean'];
  isAwareTermsOfUse: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type DefaultMessageFragment = { message: string };

export type AuthTypeFragment = { token: string, user: { id: string, name: string, email: string, phone: string, birthday?: any | null, gender?: string | null, profile: string, resetPasswordExpiration?: any | null, photoUrl?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, toolUser?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, tool: { id: string, name: string, description: string } }> | null } };

export type UserFragment = { id: string, name: string, email: string, phone: string, birthday?: any | null, gender?: string | null, profile: string, resetPasswordExpiration?: any | null, photoUrl?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, toolUser?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, tool: { id: string, name: string, description: string } }> | null };

export type StripeUserFragment = { id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, stripeUserAnswer?: Array<{ id: string, text?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, stripeUser: { id: string, stripe: { id: string } }, stripeQuestion: { id: string, questionDescription?: string | null, questionText: string }, stripeQuestionAnswer?: { id: string, order: number, title: string, description?: string | null } | null }> | null, stripeDirection?: Array<{ id: string, direction: string, correctDirection: string }> | null, user: { id: string, name: string, email: string }, stripe: { id: string, order: number } };

export type StripeUserAnswerFragment = { id: string, text?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, stripeUser: { id: string, stripe: { id: string } }, stripeQuestion: { id: string, questionDescription?: string | null, questionText: string }, stripeQuestionAnswer?: { id: string, order: number, title: string, description?: string | null } | null };

export type StripeDirectionFragment = { id: string, direction: string, correctDirection: string };

export type ToolUserFragment = { id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, tool: { id: string, name: string, description: string } };

export type StripeQuestionAnswerFragment = { id: string, order: number, title: string, description?: string | null };

export type StripeQuestionFragment = { id: string, questionText: string, questionDescription?: string | null, order: number, stripeQuestionAnswer?: Array<{ id: string, order: number, title: string, description?: string | null }> | null };

export type StripeFragment = { id: string, order: number, createdAt: any, updatedAt: any, deletedAt?: any | null, stripeQuestion?: Array<{ id: string, questionText: string, questionDescription?: string | null, order: number, stripeQuestionAnswer?: Array<{ id: string, order: number, title: string, description?: string | null }> | null }> | null, stripeUser?: Array<{ id: string }> | null };

export type FindStripesDtoFragment = { result: Array<{ isBlocked: boolean, stripe: { id: string, order: number, createdAt: any, updatedAt: any, deletedAt?: any | null, stripeQuestion?: Array<{ id: string, questionText: string, questionDescription?: string | null, order: number, stripeQuestionAnswer?: Array<{ id: string, order: number, title: string, description?: string | null }> | null }> | null, stripeUser?: Array<{ id: string }> | null } }> };

export type SearchFormQuestionFragment = { id: string, text: string, description?: string | null, questionOrder: number, createdAt: any, updatedAt: any, deletedAt?: any | null, searchFormQuestionAnswer?: Array<{ id: string, text: string, description?: string | null, answerOrder: number }> | null, searchFormUserAnswer?: Array<{ id: string, answerText?: string | null }> | null };

export type EnumTypeFragment = { id: string, type: string, value: string, description?: string | null, detail?: string | null, createdAt: any };

export type EnumByTypeQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type EnumByTypeQuery = { enumByType: Array<{ id: string, type: string, value: string, description?: string | null, detail?: string | null, createdAt: any }> };

export type StripesQueryVariables = Exact<{ [key: string]: never; }>;


export type StripesQuery = { stripes: { result: Array<{ isBlocked: boolean, stripe: { id: string, order: number, createdAt: any, updatedAt: any, deletedAt?: any | null, stripeQuestion?: Array<{ id: string, questionText: string, questionDescription?: string | null, order: number, stripeQuestionAnswer?: Array<{ id: string, order: number, title: string, description?: string | null }> | null }> | null, stripeUser?: Array<{ id: string }> | null } }> } };

export type GetSearchFormQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSearchFormQuery = { getSearchForm: Array<{ id: string, text: string, description?: string | null, questionOrder: number, createdAt: any, updatedAt: any, deletedAt?: any | null, searchFormQuestionAnswer?: Array<{ id: string, text: string, description?: string | null, answerOrder: number }> | null, searchFormUserAnswer?: Array<{ id: string, answerText?: string | null }> | null }> };

export type SaveSearchFormAnswerMutationVariables = Exact<{
  input: SaveQuestionAnswerInput;
}>;


export type SaveSearchFormAnswerMutation = { saveSearchFormAnswer: { user: { id: string, name: string, email: string }, questions: Array<{ questionOrder: number, questionText: string, answers: Array<{ answerOrder?: number | null, answerId?: string | null, answerText?: string | null }> }> } };

export type RespondStripeOneMutationVariables = Exact<{
  input: RespondQuestionWithTerm;
}>;


export type RespondStripeOneMutation = { respondStripeOne: { message: string } };

export type RespondStripeTwoMutationVariables = Exact<{
  input: RespondQuestionAnswerInput;
}>;


export type RespondStripeTwoMutation = { respondStripeTwo: { message: string } };

export type RespondStripeThreeMutationVariables = Exact<{
  input: RespondQuestionTextInput;
}>;


export type RespondStripeThreeMutation = { respondStripeThree: { message: string } };

export type RespondStripeFourMutationVariables = Exact<{
  input: RespondQuestionAnswerInput;
}>;


export type RespondStripeFourMutation = { respondStripeFour: { message: string } };

export type RespondStripeFiveMutationVariables = Exact<{
  input: RespondQuestionTextInput;
}>;


export type RespondStripeFiveMutation = { respondStripeFive: { message: string } };

export type RespondStripeSixMutationVariables = Exact<{
  input: RespondQuestionDirectionInput;
}>;


export type RespondStripeSixMutation = { respondStripeSix: { message: string } };

export type RespondStripeSevenMutationVariables = Exact<{
  input: RespondStripeInput;
}>;


export type RespondStripeSevenMutation = { respondStripeSeven: { message: string } };

export type RespondStripeEightMutationVariables = Exact<{
  input: RespondStripeInput;
}>;


export type RespondStripeEightMutation = { respondStripeEight: { message: string } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { id: string, name: string, email: string, phone: string, birthday?: any | null, gender?: string | null, profile: string, resetPasswordExpiration?: any | null, photoUrl?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, toolUser?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, tool: { id: string, name: string, description: string } }> | null } };

export type LoginMutationVariables = Exact<{
  input: AuthInput;
}>;


export type LoginMutation = { login: { token: string, user: { id: string, name: string, email: string, phone: string, birthday?: any | null, gender?: string | null, profile: string, resetPasswordExpiration?: any | null, photoUrl?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, toolUser?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, tool: { id: string, name: string, description: string } }> | null } } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { id: string, name: string, email: string, phone: string, birthday?: any | null, gender?: string | null, profile: string, resetPasswordExpiration?: any | null, photoUrl?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, toolUser?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, tool: { id: string, name: string, description: string } }> | null } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword: { message: string } };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: { message: string } };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
}
    `;
export const ToolUserFragmentDoc = gql`
    fragment ToolUser on ToolUser {
  id
  createdAt
  updatedAt
  deletedAt
  tool {
    id
    name
    description
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  phone
  birthday
  gender
  profile
  resetPasswordExpiration
  photoUrl
  createdAt
  updatedAt
  deletedAt
  toolUser {
    ...ToolUser
  }
}
    ${ToolUserFragmentDoc}`;
export const AuthTypeFragmentDoc = gql`
    fragment AuthType on AuthType {
  token
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const StripeUserAnswerFragmentDoc = gql`
    fragment StripeUserAnswer on StripeUserAnswer {
  id
  text
  createdAt
  updatedAt
  deletedAt
  stripeUser {
    id
    stripe {
      id
    }
  }
  stripeQuestion {
    id
    questionDescription
    questionText
  }
  stripeQuestionAnswer {
    id
    order
    title
    description
  }
}
    `;
export const StripeDirectionFragmentDoc = gql`
    fragment StripeDirection on StripeDirection {
  id
  direction
  correctDirection
}
    `;
export const StripeUserFragmentDoc = gql`
    fragment StripeUser on StripeUser {
  id
  createdAt
  updatedAt
  deletedAt
  stripeUserAnswer {
    ...StripeUserAnswer
  }
  stripeDirection {
    ...StripeDirection
  }
  user {
    id
    name
    email
  }
  stripe {
    id
    order
  }
}
    ${StripeUserAnswerFragmentDoc}
${StripeDirectionFragmentDoc}`;
export const StripeQuestionAnswerFragmentDoc = gql`
    fragment StripeQuestionAnswer on StripeQuestionAnswer {
  id
  order
  title
  description
}
    `;
export const StripeQuestionFragmentDoc = gql`
    fragment StripeQuestion on StripeQuestion {
  id
  questionText
  questionDescription
  order
  stripeQuestionAnswer {
    ...StripeQuestionAnswer
  }
}
    ${StripeQuestionAnswerFragmentDoc}`;
export const StripeFragmentDoc = gql`
    fragment Stripe on Stripe {
  id
  order
  createdAt
  updatedAt
  deletedAt
  stripeQuestion {
    ...StripeQuestion
  }
  stripeUser {
    id
  }
}
    ${StripeQuestionFragmentDoc}`;
export const FindStripesDtoFragmentDoc = gql`
    fragment FindStripesDTO on FindStripesDTO {
  result {
    isBlocked
    stripe {
      ...Stripe
    }
  }
}
    ${StripeFragmentDoc}`;
export const SearchFormQuestionFragmentDoc = gql`
    fragment SearchFormQuestion on SearchFormQuestion {
  id
  text
  description
  questionOrder
  createdAt
  updatedAt
  deletedAt
  searchFormQuestionAnswer {
    id
    text
    description
    answerOrder
  }
  searchFormUserAnswer {
    id
    answerText
  }
}
    `;
export const EnumTypeFragmentDoc = gql`
    fragment EnumType on EnumType {
  id
  type
  value
  description
  detail
  createdAt
}
    `;
export const EnumByTypeDocument = gql`
    query enumByType($input: String!) {
  enumByType(type: $input) {
    ...EnumType
  }
}
    ${EnumTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EnumByTypeGQL extends Apollo.Query<EnumByTypeQuery, EnumByTypeQueryVariables> {
    document = EnumByTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StripesDocument = gql`
    query stripes {
  stripes {
    ...FindStripesDTO
  }
}
    ${FindStripesDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StripesGQL extends Apollo.Query<StripesQuery, StripesQueryVariables> {
    document = StripesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSearchFormDocument = gql`
    query getSearchForm {
  getSearchForm {
    ...SearchFormQuestion
  }
}
    ${SearchFormQuestionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSearchFormGQL extends Apollo.Query<GetSearchFormQuery, GetSearchFormQueryVariables> {
    document = GetSearchFormDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveSearchFormAnswerDocument = gql`
    mutation saveSearchFormAnswer($input: SaveQuestionAnswerInput!) {
  saveSearchFormAnswer(data: $input) {
    user {
      id
      name
      email
    }
    questions {
      questionOrder
      questionText
      answers {
        answerOrder
        answerId
        answerText
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveSearchFormAnswerGQL extends Apollo.Mutation<SaveSearchFormAnswerMutation, SaveSearchFormAnswerMutationVariables> {
    document = SaveSearchFormAnswerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondStripeOneDocument = gql`
    mutation respondStripeOne($input: RespondQuestionWithTerm!) {
  respondStripeOne(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondStripeOneGQL extends Apollo.Mutation<RespondStripeOneMutation, RespondStripeOneMutationVariables> {
    document = RespondStripeOneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondStripeTwoDocument = gql`
    mutation respondStripeTwo($input: RespondQuestionAnswerInput!) {
  respondStripeTwo(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondStripeTwoGQL extends Apollo.Mutation<RespondStripeTwoMutation, RespondStripeTwoMutationVariables> {
    document = RespondStripeTwoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondStripeThreeDocument = gql`
    mutation respondStripeThree($input: RespondQuestionTextInput!) {
  respondStripeThree(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondStripeThreeGQL extends Apollo.Mutation<RespondStripeThreeMutation, RespondStripeThreeMutationVariables> {
    document = RespondStripeThreeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondStripeFourDocument = gql`
    mutation respondStripeFour($input: RespondQuestionAnswerInput!) {
  respondStripeFour(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondStripeFourGQL extends Apollo.Mutation<RespondStripeFourMutation, RespondStripeFourMutationVariables> {
    document = RespondStripeFourDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondStripeFiveDocument = gql`
    mutation respondStripeFive($input: RespondQuestionTextInput!) {
  respondStripeFive(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondStripeFiveGQL extends Apollo.Mutation<RespondStripeFiveMutation, RespondStripeFiveMutationVariables> {
    document = RespondStripeFiveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondStripeSixDocument = gql`
    mutation respondStripeSix($input: RespondQuestionDirectionInput!) {
  respondStripeSix(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondStripeSixGQL extends Apollo.Mutation<RespondStripeSixMutation, RespondStripeSixMutationVariables> {
    document = RespondStripeSixDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondStripeSevenDocument = gql`
    mutation respondStripeSeven($input: RespondStripeInput!) {
  respondStripeSeven(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondStripeSevenGQL extends Apollo.Mutation<RespondStripeSevenMutation, RespondStripeSevenMutationVariables> {
    document = RespondStripeSevenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondStripeEightDocument = gql`
    mutation respondStripeEight($input: RespondStripeInput!) {
  respondStripeEight(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondStripeEightGQL extends Apollo.Mutation<RespondStripeEightMutation, RespondStripeEightMutationVariables> {
    document = RespondStripeEightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($input: AuthInput!) {
  login(data: $input) {
    ...AuthType
  }
}
    ${AuthTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(data: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }