import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {BehaviorSubject, catchError, debounce, finalize, mergeMap, Observable, of, throwError, timer} from "rxjs";
import {AuthUtils} from "./auth.utils";
import {Router} from "@angular/router";

declare var $: any;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public requests = 0;
  public arrNoLoader = [
    '.svg',
    '/assets',
    '/svg',
    'assets',
    'AdminOfferGroupFlyerByIdGQL',
    'AdminOffersGroupGQL',
  ];
  private requestSubject = new BehaviorSubject<boolean>(false);
  private debounceLoader$ = this.requestSubject.pipe(
    debounce(show => show ? timer(0) : timer(1000))
  );

  constructor(
    public router: Router,
    private _authService: AuthService) {
    // Subscribe to the debounced loader observable
    this.debounceLoader$.subscribe(show => {
      this.toggleLoader(show);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq = req.clone();
    let countLoaderBlock = 0;
    let newHeaders = req.headers;
    const culture = localStorage.getItem('culture') as string;

    if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
      newReq = req.clone({
        headers: newHeaders.set('Authorization', 'Bearer ' + this._authService.accessToken)
      });
    }

    if (req.body != null && req.body.operationName != null) {
      this.arrNoLoader.forEach(e => {
        if (req.body.operationName.indexOf(e) >= 0) {
          countLoaderBlock++;
        }
      });
    }

    if (countLoaderBlock === 0) {
      this.requests++;
      this.requestSubject.next(true);  // Trigger loader to show
    }


    return next.handle(newReq).pipe(
      catchError((error) => {

        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Sign out
          // this._authService.signOut();
          this.handleUnauthorized();

          // Reload the app
          location.reload();
        }

        return throwError(error);
      }),
      finalize(() => {
        this.requests = Math.max(this.requests - 1, 0);
        if (this.requests === 0) {
          setTimeout(() => {
            this.toggleLoader(false);
          });
        }
      }),
      mergeMap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body && event.body.errors) {
          const errors = event.body.errors;
          if (event.body.errors[0].message?.indexOf('Access Denied')  >= 0) {
            this.handleUnauthorized();
            return throwError('Unauthorized');
          }
          if (this.hasUnauthorizedError(errors)) {
            this.handleUnauthorized();
            return throwError('Unauthorized'); // Example of re-throwing the error
          }
        }
        return of(event);
      })
    );
  }

  toggleLoader(show: boolean): void {
    if (!show) {
      $('#loaderBox').stop().fadeOut();
    } else {
      $('#loaderBox').stop().fadeIn();
    }
  }

  hasUnauthorizedError(errors: any[]): boolean {
    return errors.some(error => error.message === 'Access Denied');
  }

  handleUnauthorized() {
    this._authService.signOut();
    this.router.navigate(['/login']);
  }
}
