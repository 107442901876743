import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

declare  var $: any;

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private gameStripeDataSource = new ReplaySubject<any>(1);
  gameStripe$ = this.gameStripeDataSource.asObservable();

  private gameDataSource = new ReplaySubject<any>(1);
  game$ = this.gameDataSource.asObservable();

  updateGame(item: any) {
    this.gameDataSource.next(item);
  }

  getGame() {
    let resultValue: any;
    this.game$.subscribe(value => resultValue = value).unsubscribe();
    return resultValue;
  }

  updateGameStripe(item: any) {
    this.gameStripeDataSource.next(item);
  }

  getGameStripe() {
    let resultValue: any;
    this.gameStripe$.subscribe(value => resultValue = value).unsubscribe();
    return resultValue;
  }

}
