import {Injectable} from "@angular/core";

import {BehaviorSubject, Observable, of, switchMap} from "rxjs";
import {AuthInput, CurrentUserGQL, LoginGQL, User} from "src/generated/graphql";
import {AuthUtils} from "./auth.utils";


@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private userDataSource = new BehaviorSubject<User>(new User());
  user = this.userDataSource.asObservable();

  constructor(
    private loginGQL: LoginGQL,
    private currentUserGQL: CurrentUserGQL,
    // private authenticateGQL: AuthenticateGQL
  ) {
  }

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  getUser() {
    return this.userDataSource.value;
  }

  signInCpf(str: string, pass: string) {
    const obj = new AuthInput();
    obj.email = str;
    obj.password = pass;
    obj.origin = location.origin;
    return this.loginGQL.mutate({
      input: obj
    }).pipe(switchMap(({data}) => {
      this._authenticated = true;
      this.accessToken = data?.login?.token!;
      this.currentUser();
      return of(data);
    }));
  }

  signIn(credentials: { code: string; redirectUri: string; }) {
    /*return this.authenticateGQL.mutate({
      code: credentials.code,
      redirectUri: credentials.redirectUri
    }).pipe(switchMap(({data}) => {
      this._authenticated = true;
      this.accessToken = data?.authenticate?.authorizationToken as string;
      this.currentUser();
      return of(data);
    }));*/
  }

  signInUsingToken(): Observable<any> {
    this.currentUser();
    return of(true);
  }

  signOut(): Observable<any> {
    localStorage.removeItem('accessToken');
    this._authenticated = false;
    return of(true);
  }

  check(): Observable<boolean> {

    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      localStorage.clear();
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }
    return this.signInUsingToken();
    // return of(true);
  }

  updateUser(user: User) {
    this.userDataSource.next(user);

  }

  currentUser() {
    this.currentUserGQL.watch().valueChanges.subscribe(({data}) => {
      this.updateUser(data.currentUser as User);
    }, () => {
      this._authenticated = false;
    });
  }

}
